<template>
  <content-layout
    :breadcrumbs-items="breadcrumbsItems"
    :loading="loadingAccounts"
  >
    <cz-autocomplete
      v-model="selectedAccount"
      :items="accounts"
      style="max-width: 90%; min-width: 350px; width: 350px"
      :placeholder="$t('admin.workflowManagement.selectCustomer')"
      item-text="companyName"
      item-value="_id"
      class="pa-6"
      clearable
      return-object
    />

    <v-card v-if="selectedAccount" flat color="background">
      <v-card-title>{{ title }}</v-card-title>
      <v-toolbar flat color="background">
        <cz-button
          :title="$t('admin.workflowManagement.createNewWorkflow')"
          color="info"
          :icon-src="mdiPlusCircleOutline"
          @click="manageWorkflowDialog.show = true"
        />
      </v-toolbar>
      <cz-data-table
        v-bind="workflowsDataTable"
        :show-expand="true"
        single-expand
        :expanded.sync="expandedWorkflowItems"
        item-key="_id"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="background">
            <cz-data-table
              v-bind="workflowStepsDataTable"
              :items="currentWorkflowSteps"
              class="ma-4"
            >
              <template #foot>
                <div class="py-2 px-3">
                  <cz-button
                    color="buttonSecondary"
                    :icon-src="mdiPlusCircleOutline"
                    title="שלב חדש"
                    small
                    @click="onAddWorkflowStep(item)"
                  />
                </div>
              </template>
              <template #item.actions="{ item: step, index }">
                <div class="d-flex align-center">
                  <cz-button
                    text
                    :icon-src="mdiTrashCanOutline"
                    color="negative"
                    @click="onDeleteWorkflowStep(step, item)"
                    small
                    :disabled="workflowStepsDataTable.updating"
                  />

                  <cz-button
                    text
                    :icon-src="mdiArrowUp"
                    :disabled="index === 0 || workflowStepsDataTable.updating"
                    small
                    @click="onWorkflowStepNumReorder(step, item._id, 'up')"
                  />
                  <cz-button
                    text
                    :icon-src="mdiArrowDown"
                    :disabled="
                      index === workflowStepsDataTable.items.length - 1 ||
                      workflowStepsDataTable.updating
                    "
                    small
                    @click="onWorkflowStepNumReorder(step, item._id, 'down')"
                  />
                </div>
              </template>
            </cz-data-table>
          </td>
        </template>
        <template #item.stepsCount="{ item }">
          <cz-chip color="buttonSecondary" small>{{
            $t('customer.workflowManagementPage.stepsCount', [
              item.steps.length
            ])
          }}</cz-chip>
        </template>
        <template #item.actions="{ item }">
          <cz-menu-button
            :menu-items="actionMenuItems"
            :button-icon-size="22"
            :menu-max-width="250"
            flat
            color="info"
            :button-icon-src="mdiDotsHorizontalCircleOutline"
            clickable
            @action:clicked="onActionClicked($event, item)"
          />
          <!-- <cz-button
            text
            :icon-src="mdiTrashCanOutline"
            color="negative"
            @click="onDeleteWorkflow(item)"
          /> -->
        </template>
      </cz-data-table>
    </v-card>
    <manage-workflow-dialog
      v-if="manageWorkflowDialog.show"
      v-model="manageWorkflowDialog.show"
      v-bind="manageWorkflowDialog"
      @on-submit="onWorkflowCreate"
      @close="manageWorkflowDialog.show = false"
    />

    <manage-workflow-step-dialog
      v-if="manageWorkflowStepDialog.show"
      v-model="manageWorkflowStepDialog.show"
      v-bind="manageWorkflowStepDialog"
      @on-submit="onWorkflowStepCreate"
      @close="manageWorkflowStepDialog.show = false"
    />

    <cz-prompt-dialog
      v-if="deleteWorkflowDialog.show"
      v-model="deleteWorkflowDialog.show"
      v-bind="deleteWorkflowDialog"
      @cancel="deleteWorkflowDialog.show = false"
      @done="deleteAccountWorkflow"
    />

    <cz-prompt-dialog
      v-if="deleteWorkflowStepDialog.show"
      v-model="deleteWorkflowStepDialog.show"
      v-bind="deleteWorkflowStepDialog"
      @cancel="deleteWorkflowStepDialog.show = false"
      @done="deleteWorkflowStep"
    />

    <account-workflow-defaults-dialog
      v-if="accountWorkflowDefaultsDialog.show"
      v-model="accountWorkflowDefaultsDialog.show"
      v-bind="accountWorkflowDefaultsDialog"
      @close="accountWorkflowDefaultsDialog.show = false"
    />
  </content-layout>
</template>

<script>
import {
  getAccounts,
  getAccountWorkflows,
  createAccountWorkflow,
  deleteAccountWorkflow,
  createAccountWorkflowStep,
  deleteAccountWorkflowStep,
  updateAccountWorkflowStep,
  getAccountWorkflowSteps
} from '@/core/api';
import {
  CzAutocomplete,
  CzDataTable,
  CzButton,
  CzChip,
  CzMenuButton,
  CzPromptDialog
} from '@/components';
import {
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
  mdiArrowUp,
  mdiArrowDown,
  mdiShapeOutline,
  mdiDotsHorizontalCircleOutline
} from '@mdi/js';
import { mapActions } from 'vuex';
import orderBy from 'lodash.orderby';
import cloneDeep from 'lodash.clonedeep';
import TypesMixin from '@/shared/mixins/types.mixin';
export default {
  name: 'ManageCustomerWorkflowsPage',
  mixins: [TypesMixin],
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzPromptDialog,
    AccountWorkflowDefaultsDialog: () =>
      import('../components/AccountWorkflowDefaultsDialog.vue'),
    ManageWorkflowDialog: () =>
      import('../components/ManageWorkflowDialog.vue'),
    ManageWorkflowStepDialog: () =>
      import('../components/ManageWorkflowStepDialog.vue'),
    CzAutocomplete,
    CzDataTable,
    CzChip,
    CzMenuButton,
    CzButton
  },
  props: {
    defaultAccountId: {
      type: String
    }
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.$t('admin.adminHomePage'),
          to: {
            name: 'super-admin-home'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('admin.workflowManagementTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    actionMenuItems() {
      return [
        {
          id: 'set-defaults',
          title: this.$t('admin.workflowManagement.setDefaults'),
          icon: mdiShapeOutline
        },
        {
          id: 'delete',
          title: this.$t('common.delete'),
          icon: mdiTrashCanOutline
        }
      ];
    },
    title() {
      return this.$t('admin.workflowManagement.manageWorkflowTitle', [
        this.selectedAccount?.companyName
      ]);
    },
    currentWorkflowSteps() {
      if (!this.expandedWorkflowItems?.length) {
        return [];
      } else {
        return (this.workflowStepsDataTable.items = orderBy(
          this.expandedWorkflowItems[0].steps,
          'stepNumber'
        ));
      }
    }
  },
  created() {
    this.loadAccounts();
  },
  data() {
    return {
      mdiPlusCircleOutline,
      mdiDotsHorizontalCircleOutline,
      mdiTrashCanOutline,
      mdiArrowUp,
      mdiArrowDown,
      loadingAccounts: false,
      selectedAccount: null,
      accounts: [],
      workflowsDataTable: {
        loading: false,
        showExpand: true,
        singleExpand: true,
        columns: [
          {
            text: this.$t('admin.workflowManagement.workflowHeaders.name'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'name',
            sortable: true
          },
          {
            text: this.$t('admin.workflowManagement.workflowHeaders.type'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'typeText',
            sortable: true
          },
          {
            text: this.$t(
              'admin.workflowManagement.workflowHeaders.stepsCount'
            ),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'stepsCount',
            sortable: true
          },
          {
            text: this.$t('common.actions'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'actions',
            align: 'center',
            sortable: false
          }
        ],
        items: []
      },
      expandedWorkflowItems: [],
      workflowStepsDataTable: {
        loading: false,
        updating: false,
        columns: [
          {
            text: this.$t(
              'admin.workflowManagement.workflowStepsHeaders.stepNumber'
            ),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'stepNumber',
            sortable: false,
            width: 200
          },
          {
            text: this.$t(
              'admin.workflowManagement.workflowStepsHeaders.stepTitle'
            ),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'stepTitle',
            sortable: false
          },
          {
            text: this.$t(
              'admin.workflowManagement.workflowStepsHeaders.stepType'
            ),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'stepTypeText',
            sortable: false
          },
          {
            text: this.$t(
              'admin.workflowManagement.workflowStepsHeaders.approverRole'
            ),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'approverRoleText',
            sortable: false
          },
          {
            text: this.$t('common.actions'),
            class: 'font-weight-semibold text-subtitle-1 primary--text',
            value: 'actions',
            sortable: false
          }
        ]
      },
      manageWorkflowDialog: {
        show: false,
        width: 500,
        loading: false,
        maxWidth: '90%',
        title: this.$t('admin.workflowManagement.createNewWorkflow'),
        inset: true
      },
      manageWorkflowStepDialog: {
        show: false,
        width: 500,
        loading: false,
        maxWidth: '90%',
        defaultStepNumber: undefined,
        accountWorkflowId: '',
        accountWorkflowType: '',
        approversRoleTypes: [],
        inset: true
      },
      deleteWorkflowDialog: {
        show: false,
        title: this.$t('admin.workflowManagement.deleteWorkflow'),
        message: this.$t('admin.workflowManagement.deleteWorkflowMessage'),
        loading: false,
        item: null,
        doneButtonTitle: this.$t(
          'admin.workflowManagement.deleteWorkflowButtonTitle'
        )
      },
      accountWorkflowDefaultsDialog: {
        show: false,
        accountWorkflow: null,
        title: 'dsdsd'
      },
      deleteWorkflowStepDialog: {
        show: false,
        title: this.$t('admin.workflowManagement.deleteWorkflowStepTitle'),
        message: '',
        loading: false,
        item: null,
        doneButtonTitle: this.$t(
          'admin.workflowManagement.deleteWorkflowStepButtonTitle'
        )
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    onAddWorkflowStep(item) {
      this.manageWorkflowStepDialog.defaultStepNumber = item?.steps?.length + 1;
      this.manageWorkflowStepDialog.accountWorkflowId = item._id;
      this.manageWorkflowStepDialog.accountWorkflowType = item.type;
      this.manageWorkflowStepDialog.approversRoleTypes = this.roleTypes.filter(
        (roleItem) => roleItem.workflowSteps.includes(item.type)
      );
      this.manageWorkflowStepDialog.show = true;
    },
    onActionClicked(action, item) {
      if (action.id === 'delete') {
        this.onDeleteWorkflow(item);
      } else if (action.id === 'set-defaults') {
        this.accountWorkflowDefaultsDialog.accountWorkflow =
          structuredClone(item);
        this.accountWorkflowDefaultsDialog.title = this.$t(
          'admin.workflowManagement.setDefaultsDialogTitle',
          [item.name]
        );
        this.accountWorkflowDefaultsDialog.show = true;
      }
    },
    async onWorkflowStepCreate(form) {
      try {
        this.manageWorkflowStepDialog.loading = true;

        const createdStep = await createAccountWorkflowStep({
          workflowId: this.manageWorkflowStepDialog.accountWorkflowId,
          accountId: this.selectedAccount._id,
          ...form
        });

        this.expandedWorkflowItems[0].steps.push(createdStep);
        this.manageWorkflowStepDialog.show = false;

        this.showSuccessToastMessage(
          this.$t(
            'admin.workflowManagement.accountWorkflowStepCreatedSuccess',
            [form.stepTitle, this.expandedWorkflowItems[0].name]
          )
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.manageWorkflowStepDialog.loading = false;
      }
    },
    async onWorkflowStepNumReorder(step, workflowId, direction) {
      const index = this.workflowStepsDataTable.items.findIndex(
        (item) => item._id === step._id
      );

      if (
        (direction === 'up' && index === 0) ||
        (direction === 'down' &&
          index === this.workflowStepsDataTable.items.length - 1)
      ) {
        return;
      }

      // switch between given step with previous step
      const currentStepNumber =
        this.workflowStepsDataTable.items[index].stepNumber;

      let nextIndex = -1;

      if (direction === 'up') {
        nextIndex = index - 1;
      } else if (direction === 'down') {
        nextIndex = index + 1;
      }

      // get next step
      const nextStep = this.currentWorkflowSteps[nextIndex];
      // switch between current to next step
      // current step gets the step number of the next step and vice versa
      const _step = cloneDeep(step);
      const _nextStep = cloneDeep(nextStep);
      _step.stepNumber = _nextStep.stepNumber;
      _nextStep.stepNumber = currentStepNumber;

      try {
        this.workflowStepsDataTable.loading = true;
        this.workflowStepsDataTable.updating = true;
        // in order to save changes we first need to make sure that step numbers are unique per workflow
        // so we will first update both steps to be the negative number and then we will perform the switch
        const promisesNegativeSteps = [
          updateAccountWorkflowStep(_step._id, {
            stepNumber: _step.stepNumber * -1
          }),
          updateAccountWorkflowStep(_nextStep._id, {
            stepNumber: _nextStep.stepNumber * -1
          })
        ];

        await Promise.all(promisesNegativeSteps);

        // after we updated the steps to negative, we're ready to update the step with their real step number
        const promiseSteps = [
          updateAccountWorkflowStep(_step._id, {
            stepNumber: _step.stepNumber
          }),
          updateAccountWorkflowStep(_nextStep._id, {
            stepNumber: _nextStep.stepNumber
          })
        ];

        await Promise.all(promiseSteps);

        const { data } = await getAccountWorkflowSteps({
          workflowId
        });

        this.expandedWorkflowItems[0].steps = data;
      } finally {
        this.workflowStepsDataTable.loading = false;
        this.workflowStepsDataTable.updating = false;
      }
    },
    async loadAccounts() {
      try {
        this.loadingAccounts = true;
        const { data } = await getAccounts({
          accountType: 'customer'
        });
        this.accounts = data;
        if (this.defaultAccountId) {
          const accountToSelect = this.accounts.find(
            (item) => item._id === this.defaultAccountId
          );
          if (accountToSelect) {
            this.selectedAccount = accountToSelect;
          }
        }
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.loadingAccounts = false;
      }
    },
    onDeleteWorkflow(item) {
      this.deleteWorkflowDialog.item = item;
      this.deleteWorkflowDialog.show = true;
    },
    async deleteAccountWorkflow() {
      try {
        this.deleteWorkflowDialog.loading = true;
        await deleteAccountWorkflow(this.deleteWorkflowDialog.item._id);
        this.deleteWorkflowDialog.show = false;
        const index = this.workflowsDataTable.items.findIndex(
          (item) => item._id === this.deleteWorkflowDialog.item._id
        );
        if (index !== -1) {
          this.workflowsDataTable.items.splice(index, 1);
        }
      } finally {
        this.deleteWorkflowDialog.loading = false;
      }
    },
    onDeleteWorkflowStep(step, workflow) {
      this.deleteWorkflowStepDialog.item = step;
      this.deleteWorkflowStepDialog.message = this.$t(
        'admin.workflowManagement.deleteWorkflowStepMessage',
        [step.stepTitle, workflow.name]
      );
      this.deleteWorkflowStepDialog.show = true;
    },
    async deleteWorkflowStep() {
      try {
        this.deleteWorkflowStepDialog.loading = true;
        const workflowStepId = this.deleteWorkflowStepDialog.item._id;
        await deleteAccountWorkflowStep(workflowStepId);

        // remove workflow step locally
        const index = this.expandedWorkflowItems[0].steps.findIndex(
          (item) => item._id === workflowStepId
        );
        this.expandedWorkflowItems[0].steps.splice(index, 1);
        this.deleteWorkflowStepDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('admin.workflowManagement.accountWorkflowStepDeletedSuccess')
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.deleteWorkflowStepDialog.loading = false;
      }
    },
    async onWorkflowCreate(form) {
      try {
        this.manageWorkflowDialog.loading = true;
        const accountWorkflow = await createAccountWorkflow({
          ...form,
          accountId: this.selectedAccount._id
        });
        this.manageWorkflowDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('admin.workflowManagement.accountWorkflowCreatedSuccess', [
            this.selectedAccount.companyName
          ])
        );
        this.workflowsDataTable.items.unshift(accountWorkflow);
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.manageWorkflowDialog.loading = false;
      }
    },
    async loadAccountWorkflows() {
      try {
        this.workflowsDataTable.items = [];
        this.workflowsDataTable.loading = true;
        const { data } = await getAccountWorkflows({
          accountId: this.selectedAccount._id,
          $populate: ['user']
        });
        this.workflowsDataTable.items = data;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.workflowsDataTable.loading = false;
      }
    }
  },
  watch: {
    'manageWorkflowStepDialog.show': {
      handler(val) {
        if (!val) {
          this.manageWorkflowStepDialog.defaultStepNumber = undefined;
          this.manageWorkflowStepDialog.accountWorkflowId = '';
        }
      }
    },
    'accountWorkflowDefaultsDialog.show': {
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.accountWorkflowDefaultsDialog.title = null;
          this.accountWorkflowDefaultsDialog.accountWorkflow = null;
        }
      }
    },
    selectedAccount: {
      handler(val) {
        if (!!val) {
          this.loadAccountWorkflows();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
